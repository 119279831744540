import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './components/Declaration/Declaration.css';
import './components/Home/Home.css';
import './components/Signup/Signup.css';
import './index.css';
import { Provider } from 'react-redux';
import { NavigationProvider } from './components/Context/NavigationProvider';
import { RouterConfig } from './config/RouterConfig';
import { SocketContext } from './contexts/socket.context';
import { SocketClient } from './socket/socket';
import { QueryClient, QueryClientProvider } from 'react-query';
import ThemeComponent from './components/ThemeComponent';
import { AccountProvider } from './components/AccountProvider';
import { store } from './store';
import { ToastProvider } from './components/Toast';
import { useAppWorkspace } from './hooks/useAppWorkspace';

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon as Exclude<keyof typeof Icons, 'fas' | 'prefix'>]);
library.add(...iconList);

const queryClient = new QueryClient();

function App() {
  const appWorkspace = useAppWorkspace();
  const [isProUrl, setIsProUrl] = useState(appWorkspace.value === 'pro');
  const [socketClient, setSocketClient] = useState<SocketClient | null>(null);

  useEffect(() => {
    setIsProUrl(appWorkspace.value === 'pro');
    if (isProUrl && !socketClient) {
      const socket = new SocketClient();
      setSocketClient(socket);
    } else if (!isProUrl && socketClient) {
      socketClient?.disconnect();
      setSocketClient(null);
    }
    return () => {
      socketClient?.disconnect();
      setSocketClient(null);
    };
  }, [globalThis.location.pathname]);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeComponent>
          <ToastProvider>
            <SocketContext.Provider value={socketClient!}>
              <BrowserRouter>
                <NavigationProvider>
                  <AccountProvider>
                    <div>
                      <Routes>
                        {/* Redirection par défaut */}
                        <Route path="*" element={<Navigate to={RouterConfig.rootPage().path} replace />} />

                        {/* Pages salariés */}
                        <Route {...RouterConfig.rootPage()} />
                        <Route {...RouterConfig.profilPage()} />
                        <Route {...RouterConfig.profilFormPage()} />
                        <Route {...RouterConfig.evenementsPage()} />
                        <Route {...RouterConfig.profilvalidationPage()} />
                        <Route {...RouterConfig.loginPage()} />
                        <Route {...RouterConfig.checkCodePage()} />
                        <Route {...RouterConfig.informationPage()} />
                        <Route {...RouterConfig.changepasswordPage()} />
                        <Route {...RouterConfig.signupPage()} />
                        <Route {...RouterConfig.verifyPage()} />
                        <Route {...RouterConfig.verifyselectPage()} />
                        <Route {...RouterConfig.forgotPasswordPage()} />
                        <Route {...RouterConfig.resetPasswordPage()} />
                        <Route {...RouterConfig.createpasswordPage()} />
                        <Route {...RouterConfig.createcontactPage()} />
                        <Route {...RouterConfig.confirmationPage()} />
                        <Route {...RouterConfig.demarchesPage()} />
                        <Route {...RouterConfig.declarationFormPage()} />
                        <Route {...RouterConfig.choixfinPage()} />
                        <Route {...RouterConfig.servicesPage()} />
                        <Route {...RouterConfig.servicedetailsPage()} />
                        <Route {...RouterConfig.questionnairesuiviPage()} />
                        <Route {...RouterConfig.questionnairesuivifinPage()} />
                        <Route {...RouterConfig.questionnaireretourPage()} />
                        <Route {...RouterConfig.questionnaireretourfinPage()} />

                        <Route {...RouterConfig.historiquesalariePage()} />
                        <Route {...RouterConfig.notificationsPage()} />
                        <Route {...RouterConfig.activitesPage()} />

                        <Route {...RouterConfig.depotdocumenthistoriquefinPage()} />
                        <Route {...RouterConfig.questionnaireacceuille1Page()} />
                        <Route {...RouterConfig.questionnaireaccueil2Page()} />

                        <Route {...RouterConfig.entretienliaisonfichePage()} />

                        <Route {...RouterConfig.entretienretourfichePage()} />

                        <Route {...RouterConfig.entretienfichefinPage()} />

                        <Route {...RouterConfig.questionnairevisitefinPage()} />

                        <Route {...RouterConfig.questionnairevisitePage()} />

                        <Route {...RouterConfig.validatedemanderdvPage()} />

                        <Route {...RouterConfig.depotdocumentPage()} />

                        <Route {...RouterConfig.depotdocumentvisitePage()} />

                        <Route {...RouterConfig.depotdocumentfinPage()} />

                        <Route {...RouterConfig.meetingroomsPage()} />

                        <Route {...RouterConfig.homeHRPage()}>
                          {/* Redirection par défaut */}
                          <Route index element={<Navigate to={RouterConfig.accueilHRPage().path} replace />} />
                          <Route path="/pro/*" element={<Navigate to={RouterConfig.accueilHRPage().path} replace />} />

                          {/* Pages RH */}
                          <Route {...RouterConfig.accueilHRPage()} />
                          <Route {...RouterConfig.absencesHRPage()} />
                          <Route {...RouterConfig.progressAbsenceHRPage()} />
                          <Route {...RouterConfig.actionsHRPage()} />
                          <Route {...RouterConfig.absencesExtractHRPage()} />
                          <Route {...RouterConfig.correspTableAdminPage()} />
                          <Route {...RouterConfig.statsHRPage()} />
                          <Route {...RouterConfig.listentretienliaisonHRPage()} />
                          <Route {...RouterConfig.listentretienreturnHRPage()} />
                          <Route {...RouterConfig.adminHRPage()} />
                          <Route {...RouterConfig.usersAdminPage()} />
                          <Route {...RouterConfig.rolesAdminPage()} />
                          <Route {...RouterConfig.addserviceAdminPage()} />
                          <Route {...RouterConfig.fileAdminPage()} />
                          <Route {...RouterConfig.settingAdminPage()} />
                          <Route {...RouterConfig.serviceAdminPage()} />
                          <Route {...RouterConfig.toolsRHPage()} />
                          <Route {...RouterConfig.loginRHPage()} />
                          <Route {...RouterConfig.passwordRHPage()} />
                          <Route {...RouterConfig.confirmationRHPage()} />
                          <Route {...RouterConfig.tendancegeneraleRHPage()} />
                          <Route {...RouterConfig.linechartarretRHPage()} />
                          <Route {...RouterConfig.linecharthospitalisationRHPage()} />
                          <Route {...RouterConfig.linechartparentaliteRHPage()} />
                          <Route {...RouterConfig.radarcharttauxRHPage()} />
                          <Route {...RouterConfig.radarchartprevalenceRHPage()} />
                          <Route {...RouterConfig.radarchartoccurrenceRHPage()} />
                          <Route {...RouterConfig.barchartgraviteRHPage()} />
                          <Route {...RouterConfig.validationprofilRHPage()} />
                          <Route {...RouterConfig.detailsabsenceRHPage()} />
                          <Route {...RouterConfig.preferencesemployeeRHPage()} />
                          <Route {...RouterConfig.choixdatemanagerRHPage()} />
                          <Route {...RouterConfig.choixdatemanagerfinRHPage()} />
                          <Route {...RouterConfig.choixdatesalarieRHPage()} />
                          <Route {...RouterConfig.choixdatesstRHPage()} />
                          <Route {...RouterConfig.creerarretRHPage()} />
                          <Route {...RouterConfig.editarretRHPage()} />
                          <Route {...RouterConfig.deletearretRHPage()} />
                          <Route {...RouterConfig.declarationEndRHPage()} />
                          <Route {...RouterConfig.choixdatesalariefinRHPage()} />
                          <Route {...RouterConfig.editprofilHRPage()} />
                          <Route {...RouterConfig.entretiensLegalPage()} />
                          <Route {...RouterConfig.collaborateursPage()} />
                          <Route {...RouterConfig.serviceAnimationPage()} />
                          <Route {...RouterConfig.parcoursStateListPage()} />
                          <Route {...RouterConfig.parcoursListPage()} />
                          <Route {...RouterConfig.parcoursEditPage()} />
                          <Route {...RouterConfig.parcoursCreatePage()} />
                          <Route {...RouterConfig.parcoursDetailPage()} />
                        </Route>
                        <Route {...RouterConfig.questionsalarievisitePage()} />
                        <Route {...RouterConfig.politiqueDeConfidentialiteClientPage()} />
                        <Route {...RouterConfig.politiqueDeCookiesPage()} />
                        <Route {...RouterConfig.conditionsPage()} />
                        <Route {...RouterConfig.politiqueDeConfidentialitePage()} />
                        <Route {...RouterConfig.mentionsLegalesPage()} />
                        <Route {...RouterConfig.mentionsLegalesInformationPage()} />
                        <Route {...RouterConfig.responsableDeTraitementPage()} />
                        <Route {...RouterConfig.reportPage()} />
                      </Routes>
                    </div>
                  </AccountProvider>
                </NavigationProvider>
              </BrowserRouter>
            </SocketContext.Provider>
          </ToastProvider>
        </ThemeComponent>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
