import React from 'react';
import { AppWorkspace, useAppWorkspace } from '@/hooks/useAppWorkspace';

export const ACCESS_TOKEN_EMPLOYEE = 'access_token_employee';
export const ACCESS_TOKEN_HR = 'access_token_rh';
export const REFRESH_TOKEN = 'refreshToken';

export type UseAuthentication = (
  | {
      isAuthenticated: true;
      token: string;
    }
  | {
      isAuthenticated: false;
      token: null;
    }
) & {
  /**
   * Set a new persistent token value for workspace
   * @param token
   */
  setToken(token: string): void;
  /**
   * Set a new persistent refresh token value for workspace
   * @param token
   */
  setRefresh(refreshToken: string): void;
  /**
   * Clear token value (and all related auth values)
   */
  clear(): void;
};

/**
 * Returns an auth controller
 *
 * @param workspace
 * @returns
 */
export function useAuthentication(workspace?: AppWorkspace): UseAuthentication {
  const { value: defaultAppWorkspace } = useAppWorkspace();
  const appWorkspace = workspace ?? defaultAppWorkspace;

  const [token, _setToken] = React.useState<string | null>(getAccessToken(appWorkspace) ?? null);

  const setToken = (value: string) => setAccessToken(appWorkspace, value);
  const clear = () => clearStorageData(appWorkspace);

  React.useEffect(() => {
    const handleStorageEvent = () => {
      globalThis.dispatchEvent(new Event('tokenChange'));
    };
    const handleTokenChange = () => {
      _setToken(getAccessToken(appWorkspace));
    };

    globalThis.addEventListener('storage', handleStorageEvent);
    globalThis.addEventListener('tokenChange', handleTokenChange);
    return () => {
      globalThis.removeEventListener('storage', handleStorageEvent);
      globalThis.removeEventListener('tokenChange', handleTokenChange);
    };
  }, []);

  return token == null
    ? {
        isAuthenticated: false,
        token,
        setToken,
        setRefresh,
        clear,
      }
    : {
        isAuthenticated: true,
        token,
        setToken,
        setRefresh,
        clear,
      };
}

/*
 * Dépreciées (dette acceptée)
 */
export const getAccessToken = (appWorkspace: AppWorkspace) => {
  const storageKey = appWorkspace.match('employee') ? ACCESS_TOKEN_EMPLOYEE : ACCESS_TOKEN_HR;
  return localStorage.getItem(storageKey) ?? null;
};

export const setAccessToken = (appWorkspace: AppWorkspace, value: string) => {
  const storageKey = appWorkspace.match('employee') ? ACCESS_TOKEN_EMPLOYEE : ACCESS_TOKEN_HR;
  localStorage.setItem(storageKey, value);
  globalThis.dispatchEvent(new Event('tokenChange'));
};

export const getRefresh = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setRefresh = (value?: string) => {
  return value ? localStorage.setItem(REFRESH_TOKEN, value) : undefined;
};

export const clearStorageData = (appWorkspace: AppWorkspace) => {
  const storageKey = appWorkspace.match('employee') ? ACCESS_TOKEN_EMPLOYEE : ACCESS_TOKEN_HR;
  localStorage.removeItem(storageKey);
  localStorage.removeItem(REFRESH_TOKEN);
  globalThis.dispatchEvent(new Event('tokenChange'));
};
