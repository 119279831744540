export const AppWorkspace = {
  Employee: 'employee',
  Pro: 'pro',
} as const;
export type AppWorkspace = (typeof AppWorkspace)[keyof typeof AppWorkspace];

export interface UseAppWorkspace {
  value: AppWorkspace;
  /**
   * Return true if current workspace matches any element in `values`
   */
  match(...values: AppWorkspace[]): boolean;
}

/**
 * A hook that returns the current workspace of the app, either pro or employee
 *
 * @example
 * const workspace = useAppWorkspace(); 'pro' when location is '/pro'
 */
export function useAppWorkspace(): UseAppWorkspace {
  const value = toAppWorkspace();
  return {
    value,
    match(matches) {
      return matches.includes(value);
    },
  };
}

export const toAppWorkspace = (url?: string): AppWorkspace => {
  return /(^(\/pro\/).*)|(^\/pro$)/.test(url ?? globalThis.location.pathname) ? 'pro' : 'employee';
};
