import { useAuthentication } from '@/hooks/useAuthentication';
import { AccountContext, Notification, useAppSelector } from '@/store';
import { useMemo } from 'react';
import { defineMenu } from './defineMenu';
import { AbsenceQuery } from '@/query/AbsenceQuery';

export function useMenu() {
  const user = useAppSelector(AccountContext.getUser);
  const { isAuthenticated } = useAuthentication();
  const hasUnread = useAppSelector(Notification.hasUnread);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const absencePendingActionCount = isAuthenticated ? AbsenceQuery.usePendingAction()?.data : 0;

  return useMemo(
    () => defineMenu(user, { hasUnread, isLogin: isAuthenticated, hasEvent: (absencePendingActionCount ?? 0) > 0 }),
    [user, hasUnread, isAuthenticated, absencePendingActionCount],
  );
}
