import * as React from 'react';
import classNames from 'clsx';
import { Box, Typography, type TypographyProps, type BoxProps, Stack, Divider, styled } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { RequireLogin } from '../RequireLogin';

const paddingHorizontal = 2.5;

// Name and options
const componentName = 'AppLayoutContent';

// Component properties
export interface AppLayoutContentProps extends BoxProps {
  contentTitle?: React.ReactNode;
  contentTitleProps?: TypographyProps;
  contentTitleDivider?: boolean;
  contentTitleAfter?: React.ReactNode;
  contentMaxWidth?: 140; // spacing unit
  contentRight?: React.ReactNode;
  requireAuthentication?: boolean;
  /** default 'column */
  variant?: 'column' | 'fullWidth';
}

export function AppLayoutContent(props: AppLayoutContentProps) {
  const {
    requireAuthentication,
    variant,
    className,
    contentTitle,
    contentTitleProps,
    contentTitleDivider,
    contentTitleAfter,
    contentMaxWidth = 140,
    contentRight,
    children,
    ...otherProps
  } = props;
  const appVariant = useAppVariant();
  const paddingVertical = 4;

  return (
    <Root
      className={classNames(
        componentName,
        {
          [`${componentName}--sticky`]: !appVariant.medium,
        },
        className,
      )}
      display={'flex'}
      flexDirection={'column'}
      alignSelf={'center'}
      width={'100%'}
      maxWidth={(theme) => theme.spacing(contentMaxWidth)}
      {...otherProps}
      paddingTop={appVariant.medium ? 6 : 0}
      paddingBottom={paddingVertical}
    >
      {contentTitle ? (
        <Box className={classNames(`${componentName}__Header`)}>
          <Typography
            component="div"
            className={classNames(`${componentName}__Title`)}
            textAlign={'left'}
            fontWeight={600}
            fontSize={appVariant.medium ? 32 : 24}
            lineHeight={'normal'}
            padding={paddingVertical}
            paddingLeft={paddingHorizontal}
            paddingRight={paddingHorizontal}
            {...contentTitleProps}
          >
            {contentTitle}
          </Typography>
          {appVariant.small && contentTitleDivider ? (
            <Divider sx={{ margin: 2, marginLeft: paddingHorizontal, marginRight: paddingHorizontal }} />
          ) : undefined}
          {contentTitleAfter ? (
            <Box sx={{ marginLeft: paddingHorizontal, marginRight: paddingHorizontal }}>{contentTitleAfter}</Box>
          ) : undefined}
        </Box>
      ) : undefined}

      {requireAuthentication === true ? (
        <RequireLogin>
          <LayoutContent contentRight={contentRight} variant={variant}>
            {children}
          </LayoutContent>
        </RequireLogin>
      ) : (
        <LayoutContent contentRight={contentRight} variant={variant}>
          {children}
        </LayoutContent>
      )}
    </Root>
  );
}

export function LayoutContent(props: Pick<AppLayoutContentProps, 'children' | 'contentRight' | 'variant'>) {
  const { children, contentRight, variant } = props;

  const appVariant = useAppVariant();
  const showRightPanel = appVariant.medium;

  return (
    <Stack
      className={classNames(`${componentName}__Body`)}
      direction="row"
      spacing={12}
      paddingLeft={paddingHorizontal}
      paddingRight={paddingHorizontal}
    >
      <Box
        className={classNames(`${componentName}__Main`)}
        width={showRightPanel && variant != 'fullWidth' ? 700 / 12 + '%' : '100%'}
      >
        {children}
      </Box>
      {showRightPanel && contentRight != null ? (
        <Box className={classNames(`${componentName}__Right`)} flex={1}>
          {contentRight}
        </Box>
      ) : null}
    </Stack>
  );
}

const Root = styled(Box, {
  name: componentName,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  [`&.${componentName}--sticky .${componentName}__Header`]: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000, // Ensure it stays on top of other elements
  },
}));
