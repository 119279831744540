import { type PayloadAction, createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { UserInterface } from '@/types/user.interface';
import { clearStorageData } from '@/hooks/useAuthentication';
import { toAppWorkspace } from '@/hooks/useAppWorkspace';

export interface State {
  user: UserInterface | null;
  loading: boolean;
}

const initialState: State = {
  user: null,
  loading: true,
};

export const slice = createSlice({
  name: 'accountContext',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<State['user']>) => {
      state.user = action.payload;
    },
    setLoading: (state, action: PayloadAction<State['loading']>) => {
      state.loading = action.payload;
    },
  },
});

export const getSelf = (state: { accountContext: State }) => state[slice.name];

export const isLoading = createSelector([getSelf], (self) => self.loading);

export const getUser = createSelector([getSelf], (self) => self.user);

/**
 * Logout user
 */
export const logout = createAsyncThunk(`${slice.name}/logout`, async (_, thunkAPI) => {
  clearStorageData(toAppWorkspace());
  thunkAPI.dispatch(slice.actions.setUser(null));
});

export const { actions } = slice;
