import React, { useEffect } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@/components/Button';
import { IDocumentScannerHandle } from 'scanbot-web-sdk/@types/interfaces/i-document-scanner-handle';
import { useScanbot as Scanbot } from './Scanbot';
import { InputProps } from '@/hooks/useInput';

interface InputFileScanbotProps extends Pick<InputProps<HTMLButtonElement, Array<File>>, 'onChange'> {
  isOpen: boolean;
  onClose: () => void;
}

const getFilename = () => `${Date.now()}-scan`;

export const InputFileScanbot = (props: InputFileScanbotProps) => {
  const { isOpen, onClose, onChange } = props;

  const [scanner, setScanner] = React.useState<IDocumentScannerHandle>();
  const [isOpenScanbot, setIsOpenScanbot] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const initScan = async () => {
    setIsReady(false);
    setIsOpenScanbot(true);

    const scanbot = await Scanbot();
    setScanner(
      await scanbot.initScanner(
        async (e) => {
          const value = e.cropped as Uint8Array;
          const pngBlob = new Blob([value], { type: 'image/png' });
          const newFile = new File([pngBlob], getFilename(), { type: pngBlob.type });
          onChange?.(e, [newFile]); // FIXME: don't know what is the real type behind
          setIsOpenScanbot(false);
        },
        async () => setIsOpenScanbot(false),
      ),
    );
    setIsReady(true);
  };

  const closeScan = () => {
    scanner?.dispose();
    onClose();
  };

  useEffect(() => {
    if (isOpen) initScan();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpenScanbot) closeScan();
  }, [isOpenScanbot]);

  return (
    <>
      {isOpenScanbot && isReady && (
        <Button
          color="error"
          style={{
            zIndex: 10_001,
            position: 'fixed',
            top: '1rem',
            left: '0.5rem',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
          onClick={() => setIsOpenScanbot(false)}
        >
          {
            <>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '1rem' }} />
              Annuler
            </>
          }
        </Button>
      )}

      <div id="ScanBotContainer" style={{ zIndex: isOpenScanbot ? 10_000 : -1 }}></div>
    </>
  );
};
