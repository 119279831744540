import { io, Socket } from 'socket.io-client';
import { HOSTSERVER } from '../config';
import { ErrorMessages } from '../constants';
import { PRO_URL } from '../constants/urls.constants';
import { StatusCodes } from 'http-status-codes';
import { ACCESS_TOKEN_HR, clearStorageData, getRefresh, setAccessToken } from '@/hooks/useAuthentication';
import { authRefreshToken } from '@/services/auth';
import { toAppWorkspace } from '@/hooks/useAppWorkspace';

export class SocketClient {
  static instance: SocketClient;

  reconnectionAttempts = 0;
  navigate: ((path: string) => void) | undefined;
  socket!: Socket;

  constructor() {
    this.reconnectionAttempts = 0;
    if (!SocketClient.instance) {
      this.initializeSocket();
      SocketClient.instance = this;
    }
    return SocketClient.instance;
  }

  initializeSocket() {
    this.socket = io(HOSTSERVER, {
      autoConnect: false,
      withCredentials: true,
      auth: { token: localStorage.getItem(ACCESS_TOKEN_HR) },
    }).on('connect_error', this.handleError.bind(this));
  }

  async handleError(error: Error) {
    console.error(`Connection error due to ${error.message}`);
    if (error.message === ErrorMessages.INVALID_CREDENTIALS) {
      const existingToken = localStorage.getItem(ACCESS_TOKEN_HR);
      if (!existingToken) {
        console.error('No token found in localStorage');
        return;
      }
      if (this.reconnectionAttempts > 3) {
        console.error('Cannot reconnect to socket after several attempts');
        clearStorageData(toAppWorkspace());
        this.reconnectionAttempts = 0;
        if (this.navigate) {
          this.navigate(`${PRO_URL.PREFIXE_PRO}${PRO_URL.LOGIN}`);
        }
        return;
      }
      const isExchangeSuccessful = await this.exchangeToken();
      if (isExchangeSuccessful) {
        this.reconnectionAttempts++;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.socket.auth.token = existingToken;
        this.socket.connect();
      }
    }
  }

  async exchangeToken() {
    try {
      const refreshToken = getRefresh();
      const response = await authRefreshToken({ refreshToken });
      if (response.status === StatusCodes.OK) {
        setAccessToken(toAppWorkspace(), response.data.token);
        return true;
      }
    } catch {
      console.error('Cannot exchange token');
    }
    return false;
  }

  setNavigationHandler(navigate: (path: string) => void) {
    this.navigate = navigate;
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  deleteAction({ idNotification, length }: any) {
    this.socket.emit('delete_action', { idNotification, length });
  }

  afterDeleteEvent(callback: any) {
    this.socket.on('after_delete_action', callback);
  }

  updateLength(callback: any) {
    this.socket.on('update_length', callback);
  }

  afterAddEvent(callback: any) {
    this.socket.on('after_add_action', callback);
  }

  unsubscribeAfterAddEvent(callback: any) {
    this.socket.off('after_add_action', callback);
  }

  afterUpdateEvent(callback: any) {
    this.socket.on('after_update_action', callback);
  }

  unsubscribeAfterUpdateEvent(callback: any) {
    this.socket.off('after_update_action', callback);
  }

  unsubscribeUpdateLength(callback: any) {
    this.socket.off('update_length', callback);
  }

  unsubscribeAfterDeleteEvent(callback: any) {
    this.socket.off('after_delete_action', callback);
  }
}
