import axios from 'axios';
import { API_URL } from '../../config';
import { onRequestFulfilled } from './onRequestFulfilled';
import { onResponseRejected } from './onResponseRejected';

const clientAxios = axios.create({ withCredentials: true, baseURL: API_URL });

clientAxios.interceptors.request.use(onRequestFulfilled, (error) => Promise.reject(error));
clientAxios.interceptors.response.use((response) => response, onResponseRejected);

export default clientAxios;
