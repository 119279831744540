import React from 'react';
import { IconButton, IconButtonProps, MenuItem, useTheme, Box, Typography } from '@mui/material';
import { KeyboardArrowRightRounded, NotificationsOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotificationFilterItems, NotificationFilterType } from '@/components/NotificationFilter';
import { NotificationList, NotificationListSection, NotificationListContent } from '../NotificationList';
import { Notification, useAppDispatch } from '@/store';
import { useMUITabController } from '@/hooks/useMUITabController';
import { PageTab } from '@/components/PageTab';
import { withActivityBadge } from '@/components/AppIcon';

const Notifications = withActivityBadge(NotificationsOutlined);

export interface NavbarNotificationButtonProps extends Omit<IconButtonProps, 'style'> {
  hasUnread?: boolean;
}

export function NavbarNotificationButton(props: NavbarNotificationButtonProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { hasUnread, onClick, ...otherProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>();

  const filterController = useMUITabController({
    initialValue: NotificationFilterType.All,
    items: NotificationFilterItems,
  });
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (location.pathname !== '/notifications') dispatch(Notification.load());
    setAnchorEl(event.currentTarget);
    onClick?.(event);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color="primary"
        onClick={handleClick}
        size="medium"
        style={{
          padding: theme.spacing(1.5),
          backgroundColor: anchorEl == null ? undefined : theme.palette.grey[100],
        }}
        {...otherProps}
      >
        <Notifications badgeProps={{ invisible: !hasUnread }} />
      </IconButton>

      <NotificationList variant="menu" menuProps={{ open, anchorEl, onClose }}>
        <NotificationListSection variant="menu" boxProps={{ textAlign: 'center', p: 4, pt: 3 }}>
          <Typography fontSize={24} fontWeight={600} lineHeight={'normal'}>
            Notifications
          </Typography>
        </NotificationListSection>

        <NotificationListSection variant="menu" boxProps={{ pl: 4, pr: 4 }}>
          <PageTab {...filterController.getTabsProps()} items={filterController.control.items} />
        </NotificationListSection>

        <NotificationListContent
          emptyMessage="Vous n’avez reçu aucune notification au cours des 30 derniers jours"
          variant="menu"
          filter={filterController.control.value}
          onClickItem={onClose}
        />

        <MenuItem
          onClick={() => {
            onClose();
            navigate('/notifications');
          }}
        >
          <Box display={'flex'} gap={1} pl={2} pr={2}>
            <Typography fontSize={14} lineHeight={2} fontWeight={600}>
              Ouvrir toutes les notifications
            </Typography>
            <Typography fontSize={24} lineHeight={1}>
              <KeyboardArrowRightRounded />
            </Typography>
          </Box>
        </MenuItem>
      </NotificationList>
    </>
  );
}
