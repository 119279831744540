import { AppWorkspace, toAppWorkspace } from '@/hooks/useAppWorkspace';
import { clearStorageData } from '@/hooks/useAuthentication';
import { refreshToken } from '@/services/auth/refreshToken';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

export const onResponseRejected = async (error: onResponseRejected.Parameters): Promise<onResponseRejected.Return> => {
  const axiosError = isAxiosError(error) ? error : undefined;
  const config = axiosError?.config;
  const response = axiosError?.response;

  if (config == null || config.url == null || response == null || isExclude(config.url)) throw error;

  const unauthorizedRequest = response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.FORBIDDEN;
  if (unauthorizedRequest && config.headers.authorization != null) {
    try {
      const newToken = await refreshToken();
      const headers = { ...config.headers, authorization: `Bearer ${newToken}` };
      if (newToken) return await axios.request({ ...config, headers });
    } catch {
      const appWorkSpace = toAppWorkspace();
      clearStorageData(appWorkSpace);
      redirectOnError(appWorkSpace);
    }
  }

  throw error;
};

export namespace onResponseRejected {
  export type Parameters = unknown;
  export type Return = AxiosResponse;
}

const isExclude = (url: string) => {
  return url.includes('/auth/') || url.includes('/public/');
};

const redirectOnError = (appWorkSpace: AppWorkspace) => {
  if (appWorkSpace === 'pro') globalThis.location.href = escape(`/pro/login`);
  if (appWorkSpace === 'employee') globalThis.location.href = escape(`/login`);
};
