import { toAppWorkspace } from './useAppWorkspace';
import { ACCESS_TOKEN_EMPLOYEE, ACCESS_TOKEN_HR } from './useAuthentication';

/**
 * @deprecated Use useAuthentication
 */
export default function isAuthorized(pathname?: string) {
  const appWorkspace = toAppWorkspace(pathname);
  return appWorkspace === 'pro'
    ? !!localStorage.getItem(ACCESS_TOKEN_HR)
    : !!localStorage.getItem(ACCESS_TOKEN_EMPLOYEE);
}
