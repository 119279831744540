import React from 'react';
import { NotificationVariant } from './type';
import { Notification, useAppDispatch } from '@/store';
import type { NotificationFilterType } from '../NotificationFilter';
import { NotificationListTitle } from './NotificationListTitle';
import { NotificationListItem } from './NotificationListItem';
import { NotificationListDivider } from './NotificationListDivider';
import { useNavigate } from 'react-router-dom';

export interface NotificationListValue {
  id: string;
  content: {
    message: Array<string>;
    title: string;
    to: string;
  };
  date: string;
  hasRead: boolean;
  age?: number;
}

export interface NotificationListContentSectionProps {
  values: Array<NotificationListValue>;
  title?: string;
  onClickItem?: () => void;

  filter: NotificationFilterType;
  variant: NotificationVariant;
}

export function NotificationListContentSection(props: NotificationListContentSectionProps) {
  const { values, variant, title, onClickItem } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const markAsRead = (id: string) => dispatch(Notification.markAsRead({ id }));

  return (
    <React.Fragment>
      {values.length > 0 && title != null && <NotificationListTitle variant={variant}>{title}</NotificationListTitle>}
      {values.map((notification, index) => (
        <>
          <NotificationListItem
            key={`NotificationItemToday__${index}`}
            variant={variant}
            title={notification.content.title}
            date={notification.date}
            hasRead={notification.hasRead}
            onClick={() => {
              onClickItem?.();
              markAsRead(notification.id);
              navigate(notification.content.to);
            }}
          >
            {notification.content.message.join('\n')}
          </NotificationListItem>
          {index === values.length - 1 ? null : <NotificationListDivider variant={variant} />}
        </>
      ))}
    </React.Fragment>
  );
}
