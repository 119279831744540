import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Typography } from '@mui/material';
import { menuWidth } from './data';
import { LayoutMenuItem } from './LayoutMenuItem';
import { PRO_URL } from '@/constants/urls.constants';
import { useNavigate } from 'react-router-dom';
import isAuthorized from '@/hooks/isAuthorized';
import { RHAccountContext, useAppDispatch } from '@/store';
import { ArrowDropUpRounded } from '@mui/icons-material';
import { Button } from '@/components/Button';
import { clearStorageData } from '@/hooks/useAuthentication';
import { useAppWorkspace } from '@/hooks/useAppWorkspace';

export interface LayoutProfilProps {
  rh?: RHAccountContext.RhContext;
}

export function LayoutProfil(props: LayoutProfilProps) {
  const { rh } = props;
  const appWorkspace = useAppWorkspace();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = async () => {
    if (isAuthorized(globalThis.location.pathname)) {
      clearStorageData(appWorkspace.value);
      dispatch(RHAccountContext.actions.reset());
      dispatch(RHAccountContext.actions.setLoading(false));
    }
    navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
  };

  return (
    <div style={{ background: 'white' }}>
      <List
        sx={{
          color: '#0E0653',
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: '#0E0653',
            color: '#fff',
          },
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'secondary',
          },
        }}
        style={{
          position: 'fixed',
          bottom: 0,
          width: `calc(${menuWidth}px - 2px)`,
          marginLeft: 1,
          background: 'white',
          zIndex: 100,
          padding: 0,
          borderRadius: 0,
        }}
      >
        <Divider />

        {(!isAuthorized(globalThis.location.pathname) || rh?.rh_information == null) && (
          <div style={{ padding: '1rem' }}>
            <Button color="tertiary" fullWidth onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN)}>
              Connexion
            </Button>
          </div>
        )}

        {isAuthorized(globalThis.location.pathname) && rh?.rh_information != null && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDropUpRounded color="primary" />}
              sx={(theme) => ({
                height: theme.spacing(6.75),
              })}
            >
              <Typography display={'flex'}>
                <Avatar
                  sx={(theme) => ({
                    border: '1px solid',
                    borderColor: theme.shape.borderColorVariant,
                    height: theme.spacing(3.75),
                    width: theme.spacing(3.75),
                    background: theme.palette.common.white,
                  })}
                >
                  <Typography fontSize={10} color="primary" fontWeight={600}>
                    {`${rh?.rh_information?.firstname?.at(0)}${rh?.rh_information?.lastname?.at(0)}`}
                  </Typography>
                </Avatar>
                <Typography color="primary" fontWeight={600} fontSize={12} ml={1} mt={0.75}>
                  {`${rh?.rh_information?.firstname} ${rh?.rh_information?.lastname}`}
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <List
                style={{ margin: 0, padding: '0.5rem', paddingTop: 0, borderRadius: '0.25rem' }}
                sx={{
                  color: '#0E0653',
                }}
              >
                <LayoutMenuItem text="Profil" url={PRO_URL.PREFIXE_PRO + PRO_URL.PROFIL} />
                <LayoutMenuItem text="Se déconnecter" onClick={logout} />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
      </List>
    </div>
  );
}
