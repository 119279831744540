import React, { useState } from 'react';

import { IconButton, InputAdornment } from '@mui/material';
import { AppTextField } from '@/components/AppTextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const InputPassword = ({ register, label, error, dense }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <AppTextField
      label={label}
      error={error}
      dense={dense}
      type={'password'}
      autoComplete="password"
      InputProps={{
        inputProps: { ...register('password', { required: 'Mot de passe requis' }) },
        type: showPassword ? 'text' : 'password',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
            </IconButton>
          </InputAdornment>
        ),
        sx: (theme) => ({ paddingRight: theme.spacing(1) }),
      }}
      noHelper
    />
  );
};

export default InputPassword;
