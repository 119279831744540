import { StatusCodes } from 'http-status-codes';
import { getRefresh, setAccessToken } from '@/hooks/useAuthentication';
import { authRefreshToken } from './authRefreshToken';
import { toAppWorkspace } from '@/hooks/useAppWorkspace';

export const refreshToken = async () => {
  const refreshToken = getRefresh();

  const response = await authRefreshToken({ refreshToken });

  if (response.status === StatusCodes.OK) {
    const token: string | null = response.data.token;
    setAccessToken(toAppWorkspace(), token);
    return token;
  }
};
