import { toAppWorkspace } from '@/hooks/useAppWorkspace';
import { getAccessToken } from '@/hooks/useAuthentication';
import type { InternalAxiosRequestConfig } from 'axios';

export const onRequestFulfilled = async (config: onRequestFulfilled.Parameters): Promise<onRequestFulfilled.Return> => {
  config.headers.Accept = 'application/json';
  if (config.url == null || isExclude(config.url)) return config;
  const appWorkSpace = toAppWorkspace();
  const token = getAccessToken(appWorkSpace);
  config.headers['authorization'] = `Bearer ${token}`;
  return config;
};

export namespace onRequestFulfilled {
  export type Parameters = InternalAxiosRequestConfig;
  export type Return = InternalAxiosRequestConfig;
}

const isExclude = (url: string) => {
  return url.includes('/auth/') || url.includes('/public/');
};
