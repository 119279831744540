import axios from '@/lib/axios';
import { EmployeeInformation } from '@/types';
import { StatusCodes } from 'http-status-codes';

export async function login(data: LoginParameters) {
  try {
    return await axios.post<LoginResponse>(`/api/auth/login?appDomain=employee`, data);
  } catch (e: unknown) {
    if (e != null && typeof e === 'object' && 'response' in e) {
      const response = e.response;
      if (response != null && typeof response === 'object' && 'status' in response) {
        return { status: response.status, data: {} };
      }
    }
    return { status: StatusCodes.INTERNAL_SERVER_ERROR, data: {} };
  }
}

export interface LoginResponse {
  infoEmployee: EmployeeInformation;
  exp: string;
  token: string;
  refreshToken: string;
  status?: string;
  isResetPassword?: boolean;
}

export interface LoginResponseError {
  message: string;
  name: string;
}

export interface LoginParameters {
  email?: string;
  password?: string;
}

export const isLoginResponse = (value: LoginResponse | LoginResponseError | object): value is LoginResponse =>
  Object.hasOwn(value, 'token') && Object.hasOwn(value, 'refreshToken') && Object.hasOwn(value, 'infoEmployee');
