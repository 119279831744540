/* eslint-disable unicorn/prefer-query-selector */
import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { tous } from '@/components/ServiceThemeIcon';
import { employeeDecodeToken, fetchService } from '../../../services/employee';
import { Service, ServiceTheme, ServiceThemeIcon, ServiceThemeLabel } from '@/types';
import { ServiceList } from '@/components/ServicesList';
import { FilterInput, FilterInputElement } from '@/components/FilterInput';
import { AppLayoutContent } from '@/components/AppLayout';
import { useAppVariant } from '@/hooks/useAppVariant';

export const serviceMaxPerPage = 10;

export function ServicesContent() {
  const themes: FilterInputElement<ServiceTheme | undefined>[] = [
    { value: undefined, title: 'Tous', icon: tous },
    ...Object.values(ServiceTheme).map((theme) => ({
      value: theme,
      title: ServiceThemeLabel[`${theme}`],
      icon: ServiceThemeIcon[`${theme}`],
    })),
  ];
  const appVariant = useAppVariant();
  const navigate = useNavigate();
  const [valSearch, setValSearch] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [listService, setListService] = useState<Service[]>([]);
  const [theme, setTheme] = useState<ServiceTheme | undefined>(
    (searchParams.get('theme') as ServiceTheme) ?? undefined,
  );
  const [decode, setDecode] = useState({
    idEmployeeInformation: '',
    idAbsence: '',
    typeNotification: '',
  });

  useEffect(() => {
    if (token) {
      employeeDecodeToken({ token }).then((val) => {
        if (val.status === StatusCodes.OK) {
          setDecode(val.data.decode);
        }
      });
    }
  }, []);

  const getServicePages = async (theme: ServiceTheme | undefined, page = 1) => {
    if (page === 1) setListService([]);

    const { data } = await fetchService({ theme, page, per_page: serviceMaxPerPage });
    setListService((values) => [...values, ...data.services]);

    if (data.services.length === serviceMaxPerPage) await getServicePages(theme, page + 1);
  };

  const getServicesFiltered = async (theme: ServiceTheme | undefined) => {
    setIsLoadingServices(true);
    await getServicePages(theme, 1);
    setIsLoadingServices(false);
    setLoadingState(true);
  };

  useEffect(() => {
    getServicesFiltered(theme);
  }, [theme]);

  const filteredList = useMemo(
    () =>
      valSearch == null || valSearch.trim() === ''
        ? listService
        : listService.filter((service) => service.title.toLowerCase().includes(valSearch.toLowerCase())),
    [listService, valSearch],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const marginHorizontal = 2.5; // scroll button width

  return (
    <AppLayoutContent
      requireAuthentication
      contentTitle={'Services'}
      contentTitleAfter={
        <FilterInput
          items={themes}
          value={theme}
          onChange={(_, newTheme) => setTheme(newTheme)}
          disabled={isLoadingServices}
          sx={{
            marginLeft: -marginHorizontal,
            marginRight: -marginHorizontal,
            borderStyle: appVariant.medium ? 'none' : 'solid',
            marginBottom: 2,
            // Sticky header
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: (theme) => theme.md3.sys.color.white,
          }}
        />
      }
      variant="fullWidth"
    >
      <ServiceList
        values={filteredList}
        isLoading={isLoadingServices}
        onClick={(service) => navigate(`/services/${service.id}`)}
      />
    </AppLayoutContent>
  );
}
