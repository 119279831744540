import * as React from 'react';
import './Layout.css';
import { Box, AppBar, Toolbar, List, Divider, Drawer } from '@mui/material';
import { FooterRH } from '../../FooterRH';
import { RHMenuItem, footerWidth, menuWidth } from './data';
import { LayoutProfil } from './LayoutProfil';
import { LayoutLogo } from './LayoutLogo';
import { LayoutActions } from './LayoutActions';
import { IfPermission } from '../../IfPermission';
import { LayoutCompany } from './LayoutCompany';
import { LayoutMenu } from './LayoutMenu';
import { RHPermissionScope } from '@/types';
import { PRO_URL } from '@/constants/urls.constants';
import isAuthorized from '@/hooks/isAuthorized';
import { RHAccountContext, useAppDispatch, useAppSelector } from '@/store';
import { useNavigate } from 'react-router-dom';

export interface LayoutProps {
  menu: RHMenuItem[];
  content: JSX.Element | React.ReactNode;
}

export function Layout(props: LayoutProps) {
  const { menu, content } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rh = useAppSelector(RHAccountContext.getProfile)!; // FIXME: handle properly null value
  const isLoading = useAppSelector(RHAccountContext.isLoading);

  React.useEffect(() => {
    if (isAuthorized(globalThis.location.pathname)) dispatch(RHAccountContext.load());
    else navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: menuWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: menuWidth,
            height: `calc(100% - ${footerWidth}px)`,
            boxSizing: 'border-box',
          },
        }}
      >
        <LayoutLogo />
        <LayoutProfil rh={rh} />

        {isLoading === false && (
          <>
            <List
              sx={{
                color: '#0E0653',
                '&& .Mui-selected, && .Mui-selected:hover': {
                  bgcolor: '#0E0653',
                  color: '#fff',
                },
                '& .MuiListItemButton-root:hover': {
                  bgcolor: 'secondary',
                },
              }}
            >
              <LayoutCompany rh={rh} />

              <Divider />

              <IfPermission scope={RHPermissionScope.AbsenceManagement} action="edit">
                <LayoutActions />
              </IfPermission>

              <LayoutMenu menu={menu} />
            </List>
          </>
        )}
      </Drawer>

      <Box
        component="main"
        sx={(theme) => ({ flexGrow: 1, bgcolor: theme.palette.primaryBackground.main, minHeight: '100vh' })}
      >
        {content}

        <AppBar
          position="fixed"
          color="inherit"
          sx={{
            width: `calc(100% - ${menuWidth}px)`,
            ml: `${menuWidth}px`,
            mt: 'calc(100vh - 4rem)',
          }}
        >
          <Toolbar>
            <FooterRH />
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
}
