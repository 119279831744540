import axios from '@/lib/axios';
import { Absence, UserTypes } from '@/types';

export const authRefreshToken = async (params: authRefreshToken.Parameters = {}) => {
  return await axios.post<{
    exp: number;
    refreshToken: string;
    token: string;
    userType: UserTypes;
  }>('/api/auth/refresh', { ...params });
};

export namespace authRefreshToken {
  export type Parameters = {
    refreshToken?: string | null;
  };
  export type Return = {
    data: Array<Absence>;
    meta: {
      total: number;
    };
  };
}
